window.addEventListener('message', (event) => {
  if (event.data.type === 'app') {
    window.parent.postMessage({
      type: 'navigation',
      title: document.title,
      href: window.location.href,
    }, '*');
  }

  document.querySelectorAll('a')
    .forEach((link) => {
      const hasTargetBlank = link.getAttribute('target') === '_blank';
      const isInternalLink = ((new URL(link.href).hostname === new URL(window.location.href).hostname) || link.href.startsWith('/'));
      if (!hasTargetBlank && !isInternalLink) {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer nofollow');
      }
    });
});
